<template>
  <transition name="el-notification-fade">
    <!-- 主体 -->
    <div
      :class="['el-notification', customClass, horizontalClass, 'box']"
      v-show="visible"
      :style="positionStyle"
      @mouseenter="clearTimer()"
      @mouseleave="startTimer()"
      @click="click"
      role="alert"
    >
      <!-- <i
        class="el-notification__icon"
        :class="[typeClass, iconClass]"
        v-if="type || iconClass"
      >
      </i> -->

      <div class="icon_custom">
        <div class="icon_box">
          <svg class="icon icon-button" aria-hidden="true">
            <use href="#icon-notification-new"></use>
            
          </svg>
        </div>
      </div>

      <!-- 内容体 -->
      <div
        class="el-notification__group notify_box"
        :class="{ 'is-with-icon': typeClass || iconClass }"
      >
        <h2 class="el-notification__title" v-text="title"></h2>
        <!-- 内容区域 -->
        <div class="el-notification__content" v-show="message">
          <slot>
            <div v-if="!dangerouslyUseHTMLString">{{ message }}</div>
            <div v-else v-html="message"></div>
          </slot>
        </div>
        <!-- 关闭按钮 -->
        <div
          class="el-notification__closeBtn el-icon-close"
          v-if="showClose"
          @click.stop="close"
        ></div>
      </div>

      <!-- 更多按钮 -->
      <dropdown class="operate_btn" trigger="click" @click.stop>
        <span @click.stop> <i class="el-icon-more"></i> </span>
        <dropdown-menu slot="dropdown" @click.stop>
          <el-dropdown-item
            v-for="(item, index) in oprateList"
            :key="index"
            @click.native.stop="dropdownItemClick(item)"
            >{{ item.label }}</el-dropdown-item
          >
        </dropdown-menu>
      </dropdown>

      <!-- <div class="operate_btn">
</div> -->
    </div>
  </transition>
</template>

<script type="text/babel">
let typeMap = {
  success: "success",
  info: "info",
  warning: "warning",
  error: "error",
};
export default {
  data() {
    return {
      visible: false,
      title: "",
      message: "",
      duration: 4500,
      type: "",
      showClose: true,
      customClass: "",
      iconClass: "",
      onClose: null,
      onClick: null,
      closed: false,
      verticalOffset: 0,
      timer: null,
      dangerouslyUseHTMLString: false,
      position: "top-right",
      //  新增参数： 当前数据
      data: "",
      // 操作菜单列表
      oprateList: [
        // {
        //   label:"aaa",
        // }
      ],
    };
  },

  computed: {
    typeClass() {
      return this.type && typeMap[this.type]
        ? `el-icon-${typeMap[this.type]}`
        : "";
    },

    horizontalClass() {
      return this.position.indexOf("right") > -1 ? "right" : "left";
    },

    verticalProperty() {
      return /^top-/.test(this.position) ? "top" : "bottom";
    },

    positionStyle() {
      return {
        [this.verticalProperty]: `${this.verticalOffset}px`,
      };
    },
  },
  components: {
    dropdown: () => import("../../dropdown/src/dropdown.vue"),
    "dropdown-menu": () => import("../../dropdown/src/dropdown-menu.vue"),
  },
  watch: {
    closed(newVal) {
      if (newVal) {
        this.visible = false;
        this.$el.addEventListener("transitionend", this.destroyElement);
      }
    },
  },

  methods: {
    destroyElement() {
      this.$el.removeEventListener("transitionend", this.destroyElement);
      this.$destroy(true);
      this.$el.parentNode.removeChild(this.$el);
    },

    click() {
      this.$bus.$emit("notifyClick", this.data);
      if (typeof this.onClick === "function") {
        this.onClick();
      }
    },

    close() {
      this.closed = true;
      if (typeof this.onClose === "function") {
        this.onClose();
      }
    },

    clearTimer() {
      clearTimeout(this.timer);
    },

    startTimer() {
      if (this.duration > 0) {
        this.timer = setTimeout(() => {
          if (!this.closed) {
            this.close();
          }
        }, this.duration);
      }
    },
    keydown(e) {
      if (e.keyCode === 46 || e.keyCode === 8) {
        this.clearTimer(); // detele 取消倒计时
      } else if (e.keyCode === 27) {
        // esc关闭消息
        if (!this.closed) {
          this.close();
        }
      } else {
        this.startTimer(); // 恢复倒计时
      }
    },

    notifyClick() {
      this.$emit("notifyClick", this.data);
    },
    dropdownItemClick(e) {
      this.$bus.$emit("dropdownItemClick", e);
    },
  },
  mounted() {
    if (this.duration > 0) {
      this.timer = setTimeout(() => {
        if (!this.closed) {
          this.close();
        }
      }, this.duration);
    }
    document.addEventListener("keydown", this.keydown);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.keydown);
  },
};
</script>

<style lang="scss" scoped>
.box {
  min-height: 70px;
  padding: 14px 26px 14px 0px !important;
  &:hover .operate_btn {
    visibility: visible;
  }

  display: flex;
  .icon_custom {
    width: 40px;
    flex-shrink: 0;
    display: flex;
    /* align-items: center; */
    justify-content: center;
    margin-top: 3px;
    .icon_box {
      // background-color: #f5f5f5;
      width: 24px;
      height: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      .icon{
        width: 100%!important;
        height: 100%!important;
      }
    }
  }
}
.notify_box {
  cursor: pointer;
  flex: 1;
}

.operate_btn {
  visibility: hidden;
  position: absolute;
  right: 14px;
  bottom: 6px;
  width: 16px;
  height: 16px;
  border: 1px solid #ececed;
  border-radius: 50%;
  background: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .icon_bottom {
    width: 12px;
  }
  .dropdown_box {
    position: absolute;
    left: -79px;
    top: 20px;
    width: 100px;
    background-color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    border: 1px solid #e1e1e1;
    display: none;
    z-index: 99999999 !important;
  }
}
</style>

