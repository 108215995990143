<template>
  <div>
    <!-- sse 消息通知组件 -->
    <audio :src="require('./bell.mp3')" ref="musicControl"></audio>
  </div>
</template>

<script>
import * as api from "./api.js";
import WSSE from "wsse-sdk";

import UTIL from "./notifyLimitUtils";
const notifyLimitUtils = new UTIL();
export default {
  data() {
    return {
      // wsse是否链接
      isConnectWsse: false,
      /**
       * 消息展示类型
       * 使用里面的 popuptype："popup" | "timepiece"
       * 匹配： popupoperatetype
       */
      PopupTypeList: [],
    };
  },
  props: {
    sseMsgServiceAddress: {
      type: String,
      default: "",
    },
  },
  mounted() {
    //    setInterval(() => {
    //      notifyLimitUtils.active({
    //       message: "contentText",
    //       duration: 0,
    //       data: {
    //         relatedId: "",
    //         functionData: "",
    //       },
    //       oprateList: [
    //         // {
    //         //   label: "关闭声音",
    //         //   value: "关闭声音",
    //         // },
    //         {
    //           label: "转到通知设置",
    //           value: "转到通知设置",
    //         },
    //       ],
    //     });

    //  this.$bus.$emit("wsseMessage", "e.data");

    //    }, 1000*2);

    /**
     * 消息弹窗点击  跳转到详情页
     */
    this.$bus.$on("notifyClick", this.notifyClick);

    /**
     * 下拉菜单点击
     */
    this.$bus.$on("dropdownItemClick", this.dropdownItemClick);

    this.queryPopupType();
  },
  beforeDestroy() {
    /**
     * 消息弹窗点击  跳转到详情页
     */
    this.$bus.$off("notifyClick", this.notifyClick);

    /**
     * 下拉菜单点击
     */
    this.$bus.$off("dropdownItemClick", this.dropdownItemClick);
  },
  methods: {
    /**
     * 下拉菜单点击
     */
    dropdownItemClick(e) {
      const { value } = e;
      if (value === "转到通知设置") {
        window.location.href = "/setup/#/settings/setuppage/notification";
      }
    },
    /**
     * 消息弹窗点击
     */
    notifyClick(e) {
      const { functionData, relatedId } = e;
      const standardDetail = [
        "taskAlert", //任务
        "eventAlert", //事件
        "approval", //审批
        "Marketsea", //公海池
        "campaignMsg", //市场活动成员
      ];
      if (standardDetail.includes(functionData)) {
        /**
         *  ! 标准的跳转到详情页
         * 任务和事件
         * 客户和联系人
         * 业务机会
         * 审批
         * 个案
         * 工作订单
         * 公海池
         * 市场活动成员
         */
        this.$router.push(`/commonObjects/detail/${relatedId}/DETAIL`);
      } else if (
        functionData === "ChatterGroup" ||
        functionData === "showmsg"
      ) {
        // 小组/ccchart
        //  this.$router.push(`/commonObjects/detail/${e.relatedId}/DETAIL`);
        this.$router.push({
          path: `/groupObject/groupDetails`,
          query: {
            id: relatedId,
            // isStatus: isStatus,
          },
        });
      } else if (functionData === "mailToCloudccApi") {
        // 邮件
        this.$router.push({
          path: `/MailDetail`,
          query: {
            id: relatedId,
            index: 0,
            type: 1,
            // inboxlist: this.inboxlist,
          },
        });
      }
    },
    /**
     * 放歌
     */
    PlayMusic() {
      let musicControl = this.$refs.musicControl;
      if (musicControl.paused) {
        //判读是否播放
        musicControl.play(); //没有就播放
      }
    },
    async queryPopupType() {
      let {
        data: { list },
      } = await api.queryPopupType();
      this.PopupTypeList = list;
      // 请求完展示类型，再链接sse
      this.initSSE();
    },
    initSSE() {
      // 如果已经链接sse，则在页面显示隐藏时，不重新开启新的wsse
      if (this.isConnectWsse) return;
      // 建立sse站内信链接
      let wsse = new WSSE({
        url: `${
          this.sseMsgServiceAddress
        }/connect/?accessToken=${this.$CCDK.CCToken.getToken()}`,
         debugger:false
      });

      wsse.on({
        open: () => {
          this.isConnectWsse = true;
        },
        message: (e) => {
          /**
           * 如果没有消息就终止
           */
          if (!e?.data) return;
          let data = JSON.parse(e.data);
          if (Array.isArray(data)) {
            // 如果是连接心跳消息，则跳过
            return;
          }
          const {
            popupoperatetype,
            contentText,
            relatedId,
            function: functionData,
          } = data;
          // 放歌
          this.PlayMusic();
          this.PopupTypeList.forEach((item) => {
            // 当前收到的消息匹配到相应的展示类型，则展示对应展示
            if (item.popupoperatetype === popupoperatetype) {
              if (item.popuptype === "popup") {
                // 弹窗展示
                // notify({
                //   message: contentText,
                //   duration: 0,
                //   data: {
                //     relatedId,
                //     functionData,
                //   },
                //   oprateList: [
                //     {
                //       label: "关闭声音",
                //       value: "关闭声音",
                //     },
                //     {
                //       label: "转到通知设置",
                //       value: "转到通知设置",
                //     },
                //   ],
                // });

                /**
                 * 弹窗提示
                 */
                notifyLimitUtils.active({
                  message: contentText,
                  duration: 1000 * 10,
                  // duration: 0,
                  data: {
                    relatedId,
                    functionData,
                  },
                  oprateList: [
                    // {
                    //   label: "关闭声音",
                    //   value: "关闭声音",
                    // },
                    {
                      label: "转到通知设置",
                      value: "转到通知设置",
                    },
                  ],
                });
                // 触发全局事件更新消息数量
                this.$bus.$emit("wsseBusEmit");
              } else if (item.popuptype === "timepiece") {
                /**
                 * 气泡展示
                 * 原本的消息提示弹窗 事件发射
                 */
                this.$bus.$emit("wsseMessage", e.data);
                // 触发全局事件更新消息数量
                this.$bus.$emit("wsseBusEmit");
              }
            }
          });
        },
        error: () => {
          this.isConnectWsse = false;
        },
      });
    },
  },
};
</script>

<style lang="scss">
/**
notify 消息通知样式
*/
.notify_item {
  cursor: pointer;
  position: relative;
  display: flex;
  width: 292px;
  min-height: 43px;
  .notify_icon {
    width: 36px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
  }

  .icon_left {
    width: 18px;
    height: 18px;
  }

  .operate_btn {
    position: absolute;
    right: -10px;
    bottom: -6px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #d2d3d5;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon_bottom {
      width: 12px;
    }
    .dropdown_box {
      position: absolute;
      left: -79px;
      top: 20px;
      width: 100px;
      background-color: #fff;
      border-radius: 4px;
      padding: 2px 6px;
      box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
      border: 1px solid #e1e1e1;
      display: none;
      z-index: 99999999 !important;
    }
  }
}

.el-notification {
  overflow: visible;
}
.el-notification__content {
  margin: 0 !important;
}
.el-notification__group {
  margin-left: 0 !important;
}

.open {
  display: block !important;
}
</style>