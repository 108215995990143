/**
 * 消息通知弹窗封装
 * 限制同时打开数量
 */
 import notify from "./componenets/notification/index.js";
export default class Notify {
  constructor(config) {
    this.limit = config?.limit || 3;
    // 队列
    this.queue = [];
  }
  active(option) {
    const { queue, limit } = this;
    if (queue.length >= limit) {
      this.queue[0].close();
      this.queue.shift();
    }
    this.queue.push(notify(option));
  }
}
